<template>
  <page-layout :title="$t('backoffice.reconciliation.title')" :fixed-footer="true">
    <div class="backoffice_general-orders">
      <el-alert type="info" :closable="false" show-icon>
        <span v-html="$t('backoffice.orders.reconciliation.alert')"></span>
      </el-alert>
      <div>
        <el-form ref="form">
          <el-row :gutter="12">
            <el-col :span="4">
              <base-input
                ref="mrpOspId"
                prop="mrpOspId"
                v-model="form.mrpOspId"
                name="mrpOspId"
                :label="$t('reconciliation.label.mrpOspId')"
                :validations="$v.form.mrpOspId"
              />
            </el-col>
            <el-col :span="4">
              <base-input
                ref="bookingId"
                prop="bookingId"
                v-model="form.bookingId"
                name="bookingId"
                :label="$t('reconciliation.label.bookingId')"
                :validations="$v.form.bookingId"
              />
            </el-col>
            <el-col :span="4">
              <base-select
                :label="$t('reconciliation.label.status')"
                ref="status"
                prop="status"
                :items="statusList"
                @input="addStatusToform"
                :validations="$v.form.status"
                :clearable="true"
              />
            </el-col>
          </el-row>
        </el-form>
      </div>
      <el-table
        id="ordersTable"
        ref="table"
        :data.sync="reconciliationsData.content"
        @sort-change="sortChange"
        :default-sort="defaultSort"
        :empty-text="$t('general.empty_table')"
        style="width: 100%"
      >
        <el-table-column prop="icon" width="37">
          <template>
            <i class="ri-contacts-line"></i>
          </template>
        </el-table-column>
        <el-table-column
          class-name="text-bold"
          prop="bookingId"
          :label="$t('reconciliation.label.bookingId')"
          min-width="180"
          sortable="custom"
          :sort-orders="sortOrders"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="mrpOspId"
          :label="$t('reconciliation.label.mrpOspId')"
          :show-overflow-tooltip="true"
          sortable="custom"
          :sort-orders="sortOrders"
          min-width="110"
        ></el-table-column>
        <el-table-column
          prop="expectedAmount"
          :label="$t('reconciliation.label.expectedAmount')"
          min-width="120"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          class-name="actions-cell justify-between"
          prop="actualAmount"
          :label="$t('reconciliation.label.actualAmount')"
          :show-overflow-tooltip="true"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="currency"
          :label="$t('reconciliation.label.currency')"
          :show-overflow-tooltip="true"
          min-width="110"
        ></el-table-column>

        <el-table-column
          class-name="text-bold"
          prop="status"
          :label="$t('reconciliation.label.status')"
          :show-overflow-tooltip="true"
          sortable="custom"
          :sort-orders="sortOrders"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="dateTime"
          :label="$t('reconciliation.label.lastUpdatedOn')"
          :show-overflow-tooltip="true"
          min-width="150"
        ></el-table-column>
        <el-table-column
          fixed="right"
          class-name="actions-cell"
          prop="buttons"
          :label="''"
          width="100"
        >
          <template slot-scope="{row}">
            <base-button
              v-if="!row.manualReconciliation"
              :label="$t('general.confirm')"
              type="primary"
              size="small"
              @click.stop="handleConfirm(row)"
            ></base-button>
          </template>
        </el-table-column>
      </el-table>

      <reconciliation-confirm ref="ReconcilationConfirm" @confirm="confirm"></reconciliation-confirm>
    </div>
    <template #footer v-if="reconciliationsData.totalPages > 1">
      <base-pagination v-model="pagination.page" :pages="reconciliationsData.totalPages" @input="paginationChange" />
    </template>
  </page-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { debounce, isEqual } from 'lodash'
import TableLoader from '@/mixins/TableLoader'
import PageLayout from '../layouts/PageLayout'
import ReconciliationConfirm from '@/components/Backoffice/Reconciliation/ReconciliationConfirm'
import { validateBookingId, validateMrpOspId, validateStatus } from '@/app/helpers/validate'

export default {
  name: 'BackofficeReconciliation',
  components: {
    PageLayout,
    ReconciliationConfirm
  },
  mixins: [TableLoader],
  data () {
    return {
      jsonedFilters: '',
      form: {
        mrpOspId: '',
        bookingId: '',
        status: []
      },
      defaultSort: { order: 'ASCENDING', prop: 'mrpOspId' },
      sortOrders: ['ascending', 'descending'],
      pagination: {
        page: 0,
        size: 25
      },
      statusList: [
        { value: 'NO_PAYMENT' },
        { value: 'UNEXPECTED_PAYMENT' },
        { value: 'DIFFERENT_PAYMENT' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      reconciliationsData: 'reconciliations/getReconciliations'
    }),
    preparedQueryData () {
      const finalDataObject = {
        pagination: {
          page: this.pagination.page,
          size: this.pagination.size,
          sort: {
            sortOrders: [{
              direction: this.defaultSort.order,
              property: this.defaultSort.prop
            }]
          }
        }
      }

      if (this.form.bookingId) {
        finalDataObject.bookingId = this.form.bookingId
      }
      if (this.form.mrpOspId) {
        finalDataObject.mrpOspId = this.form.mrpOspId
      }

      if (this.form.status && this.form.status.length > 0) {
        if (this.form.status[0]) {
          finalDataObject.statuses = this.form.status
        }
      }

      return finalDataObject
    }
  },
  watch: {
    form: {
      deep: true,
      handler: debounce(async function () {
        this.$v.form.$touch()
        if (this.$v.form.$invalid) {
          return true
        }
        const loading = this.TableLoader_show()
        if (!isEqual(this.jsonedFilters, JSON.stringify(this.form))) {
          this.jsonedFilters = JSON.stringify(this.form)
          this.pagination.page = 0
        }
        await this.getReconciliationProblems(this.preparedQueryData)
        loading.close()
      }, 700)
    }
  },
  validations () {
    return {
      form: {
        bookingId: {
          validateBookingId
        },
        mrpOspId: {
          validateMrpOspId
        },
        status: {
          validateStatus
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getReconciliationProblems: 'reconciliations/getReconciliationsProblems',
      manualOverride: 'reconciliations/manualOverride',
      clearState: 'reconciliations/clearState'
    }),
    async confirm (form) {
      await this.manualOverride(form)
      this.getReconciliationProblems(this.preparedQueryData)
    },
    handleConfirm (reconciliation) {
      this.$refs.ReconcilationConfirm.show(reconciliation)
    },
    sortChange (sort) {
      if (sort.order) {
        delete sort.column
        this.defaultSort = sort
        this.defaultSort.order = this.defaultSort.order.toUpperCase()
        this.getReconciliationProblems(this.preparedQueryData)
      }
    },
    paginationChange (page) {
      this.pagination.page = page
      this.getReconciliationProblems(this.preparedQueryData)
    },
    addStatusToform (status) {
      this.form.status = []
      this.form.status.push(status)
    }
  },
  async mounted () {
    const loading = this.TableLoader_show()
    this.jsonedFilters = JSON.stringify(this.jsonedFilters)
    await this.getReconciliationProblems(this.preparedQueryData)
    loading.close()
  },
  beforeDestroy () {
    this.clearState()
  }
}
</script>
